<template>
  <div class="sm:w-max-4xl w-full">
    <vs-table class="w-full relative" ref="table">
      <template #thead>
        <vs-tr>
          <vs-th class="!w-2">
            <i class="bx bx-check" />
          </vs-th>
          <vs-th class="!w-1/4"> Id</vs-th>
          <vs-th class="!w-1/6"> User</vs-th>
          <vs-th class="!w-1/8"> Balance</vs-th>
          <vs-th> Role</vs-th>
          <vs-th> hardwareID</vs-th>
          <vs-th> Created At</vs-th>
        </vs-tr>
      </template>
      <template #tbody v-if="users">
        <vs-tr
          :key="i"
          v-for="(user, i) in users.docs"
          :data="user"
          class="cursor-pointer"
          :is-selected="selected === user"
        >
          <vs-td>
            <i v-if="!checkActive(user)" class="bx bx-x"></i>
            <i v-else class="bx bx-check"></i>
          </vs-td>
          <vs-td>
            {{ user._id }}
          </vs-td>
          <vs-td>{{ user.username }}</vs-td>
          <vs-td>{{ user.balance }} $</vs-td>
          <vs-td>{{ getUserRole(user) }}</vs-td>
          <vs-td @click="copyHardwareID(user)">Copy hardware</vs-td>
          <vs-td>
            {{ user.createdAt | formatTimestamp }}
          </vs-td>
        </vs-tr>
      </template>
      <template #footer v-if="users">
        <vs-pagination v-model="page" :length="users.totalPages" />
      </template>
    </vs-table>
  </div>
</template>

<script>
import { getAllUsersByAdmin } from "@/services/ApiService";

export default {
  name: "UsersTable",
  props: {
    users: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    page: 1,
    max: 10,
  }),
  methods: {
    checkActive(user) {
      return user.hardwareID;
    },
    getUserRole(user) {
      const roles = {
        20: "Admin",
        10: "Seller",
        0: "User",
      };

      return roles[user.role];
    },
    copyHardwareID(user) {
      navigator.clipboard.writeText(user.hardwareID || "No hardwareID");

      this.$vs.notification({
        title: "Hardware ID copied",
        text: user.hardwareID || "No hardware ID",
        color: "success",
        position: "bottom-right",
      });
    },
    getUsers() {
      const loading = this.$vs.loading({
        type: "points",
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
      });

      getAllUsersByAdmin(this.page)
        .then((res) => {
          this.users = res;
          loading.close();
        })
        .catch((error) => {
          loading.close();

          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
        });
    },
  },
  watch: {
    page() {
      this.getUsers();
    },
  },
};
</script>

<style scoped>

</style>
