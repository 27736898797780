<template>
  <div class="flex justify-center h-full w-full">
    <div class="w-full sm:w-max-4xl">
      <div v-if="users" class="w-full flex flex-col gap-4">
        <div class="flex justify-between w-full">
          <span class="text-2xl font-bold">User List</span>
        </div>
        <div class="flex items-center">
          <vs-input
            v-model="input"
            placeholder="Username or Hardware ID"
            class="w-full"
            block
            v-on:keyup.enter="getUsers"
          ></vs-input>

          <vs-button class="w-10 h-10 text-center" block icon @click="getUsers">
            <i class="bx bx-search" />
          </vs-button>
        </div>

        <UsersTable :users="users" />
      </div>
    </div>
  </div>
</template>

<script>
import { getAllUsersByAdmin } from "@/services/ApiService";
import UsersTable from "@/views/admin/users/UsersTable";

export default {
  name: "Users",
  components: { UsersTable },
  data() {
    return {
      page: 1,
      input: undefined,
      users: undefined,
    };
  },
  methods: {
    getUsers() {
      const loading = this.$vs.loading({
        type: "points",
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
      });

      getAllUsersByAdmin(this.page, this.input)
        .then((res) => {
          this.users = res;
          loading.close();
        })
        .catch((error) => {
          loading.close();

          this.$vs.notification({
            color: "danger",
            name: "Error",
            text: error.response.data.message,
          });
        });
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style scoped>

</style>
